<template>
  <el-dialog :visible.sync="visible" :title="form && form.id ? '编辑节假日' : '新增节假日'" append-to-body :close-on-click-modal="false" width="600px" @closed="form = null">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="100px" label-suffix=":" v-if="form">
      <el-form-item prop="name" label="名称">
        <el-input v-model.trim="form.name" :maxlength="20" clearable />
      </el-form-item>
      <el-form-item prop="yearDt" label="年度">
        <el-date-picker v-model="form.yearDt" type="year" placeholder="请选择年度">
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="begDate" label="开始日期">
        <el-date-picker v-model="form.begDate" type="date" placeholder="请选择开始日期" value-format="timestamp" v-if="form.yearDt">
        </el-date-picker>
        <span v-else>请选择年度</span>
      </el-form-item>
      <el-form-item prop="endDate" label="结束日期">
        <el-date-picker v-model="form.endDate" type="date" placeholder="请选择结束日期" value-format="timestamp" v-if="form.yearDt">
        </el-date-picker>
        <span v-else>请选择年度</span>
      </el-form-item>
      <el-form-item prop="isWorkDay" label="类型">
        <el-radio-group v-model="form.isWorkDay">
          <el-radio-button label="false">节假日</el-radio-button>
          <el-radio-button label="true">补班</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button type="text" @click="visible = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add,edit } from "@/api/holiday";

export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      form: null,
      loading: false,
      rules: {
        name: [{ required: true, message: "请填写名称" }],
        yearDt: [{ required: true, message: "请选择年度" }],
        begDate: [{ required: true, message: "请选择开始日期" }],
        endDate: [{ required: true, message: "请选择结束日期" }],
      },
    };
  },
  methods: {
    doSave() {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          this.form.year = this.form.yearDt.format('yyyy');
          this.form.begDate 
          this.loading = true;
          if(this.isAdd){
            this.doAdd();
          }else{
            this.doEdit();
          }
        });
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.visible = false;
        this.$parent.init()
      }).catch(err => {
        this.loading = false;
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false;
        this.visible = false;
        this.$parent.init()
      }).catch(err => {
        this.loading = false;
      })
    },
    resetForm(form) {
      if(form && form.year) form.yearDt = new Date(form.year,1,1);
      this.form = form || {
        name: "",
        yearDt: new Date(),
        begDate:null,
        endDate:null,
        isWorkDay:false,
      };
      this.visible = true;
    },
  },
};
</script>