<template>
  <div class="app-container">
    <div class="head-container">
      <el-input v-model.trim="query.name" clearable placeholder="节假日名称搜索" class="filter-item" style="width: 180px" @keyup.enter.native="toQuery" />
      <el-date-picker v-model="query.year" type="year" placeholder="请选择年度" class="filter-item" style="width: 100px" value-format="yyyy"></el-date-picker>
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="margin-top: 10px;">
      <el-table-column prop="name" label="名称" min-width="200" />
      <el-table-column prop="year" label="年度" width="100" />
      <el-table-column prop="begDate" label="开始日期" width="140" :formatter="v=>{return new Date(v.begDate).format('yyyy-MM-dd')}" />
      <el-table-column prop="endDate" label="结束日期" width="140" :formatter="v=>{return new Date(v.endDate).format('yyyy-MM-dd')}" />
      <el-table-column prop="isWorkDay" label="类型">
        <template slot-scope="scope">
          <el-tag type="warning" v-if="scope.row.isWorkDay">补班</el-tag>
          <el-tag type="success" v-else>节假日</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="100" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <e-form ref="form" :is-add="isAdd" />
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import eForm from "./form";
import { del } from "@/api/holiday";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      isAdd: false,
      query: {
        key: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    beforeInit() {
      this.url = "api/holiday";
      this.params = Object.assign({}, this.query);
      return true;
    },
    add() {
      this.isAdd = true;
      this.$refs.form && this.$refs.form.resetForm();
    },
    edit(data) {
      this.isAdd = false;
      this.$refs.form &&
        this.$refs.form.resetForm(JSON.parse(JSON.stringify(data)));
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
        });
    },
  },
};
</script>